import axios, { AxiosInstance } from 'axios'
import { components } from 'api/generated-schema'

let API: AxiosInstance

export const getBaseUrlForAssets = (environment?: string | null): string => {
  const urls = {
    preprod: 'http://pcs-ui.porsche-preview.com',
    prod: 'http://pcs-ui.porsche.com',
    dev: 'http://dev-pcs-ui.porsche-preview.com',
    local: 'http://localhost:3000',
  }
  // @ts-ignore
  return urls[environment] || urls.local
}

export const initApiForWebcomponent = (token: string, environment: string | null): void => {
  let apiBaseUrl = 'https://api.dev-pcs.porsche.com/mno-connectivity'
  if (environment === 'preprod') {
    apiBaseUrl = 'https://api.preview-pcs.porsche.com/mno-connectivity'
  }
  if (environment === 'prod') {
    apiBaseUrl = 'https://api.pcs.porsche.com/mno-connectivity'
  }
  initApi(apiBaseUrl, token)
}

export const getWidgetState = (vin: string, country: string): Promise<WidgetState> =>
  API.get('/v1/sim/eu-release4-connectivity/widget', {
    params: { vin, country: country.toUpperCase() },
  })
    .then((res) => res.data)
    .catch(() => {
      return {
        isError: true,
      }
    })

const initApi = (apiUrl: string, token: string): void => {
  API = isLocalOrTest()
    ? axios.create()
    : axios.create({
        baseURL: apiUrl,
        withCredentials: true,
      })
  if (token) {
    setupRequestInterceptors(token)
  }
}

const isLocalOrTest = (): boolean =>
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test'

const setupRequestInterceptors = (token: string): void => {
  API.interceptors.request.use((config) => {
    if (config?.headers && token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  })
}

export type ConnectivityProductStateV3 = components['schemas']['EuConnectivityProductStateDTOV3']
export type WidgetState = components['schemas']['WidgetStateDTO']
